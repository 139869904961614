<template>
    <div class="Profile-main">
        <div class="Profile-main_left">
            <div class="Profile-main_photo white-block" v-loading="loadingService">
                <p class="photo_title">{{ $t('profile.profile.avatar') }}</p>
                <div class="position-relative" v-if="this.avatarImage">
                    <el-upload
                        class="avatar-uploader"
                        :headers="{Authorization: 'Bearer ' + this.$store.state.auth.token}"
                        :action="apiRoot + '/api/user/avatar/update'"
                        :show-file-list="false"
                        accept="image/png,image/jpeg,image/webp"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img width="164" height="164" :src="this.avatarImage" class="avatar" alt="">
                        <div class="Profile-main_camera">
                            <img src="/images/camera.svg" alt="camera" width="20" height="18">
                        </div>
                    </el-upload>
                </div>
                <div class="avatar_info">
                    <p class="name">{{ account.last_name + " " + account.first_name }}</p>
                    <p class="role">{{ $t('breadcrumb.teacher') }}</p>
                </div>
                <button class="Profile-main__back-btn" v-show="visibleBtnBack"
                        v-on:click="showInputs(false, false, false, false, true, false)">
                    <img src="/images/profile/arrow-back.svg" width="9" height="15" alt="">
                </button>
            </div>

            <div class="Profile-main__btns-mob" v-show="visibleMenuBtns">
                <button class="Profile-main__btn" v-on:click="showInputs(true, false, false, true, false, true)">
                    <img src="/images/profile/main-icon.svg" alt="">
                    {{ $t('profile.profile.main-info') }}
                </button>

                <button class="Profile-main__btn" v-on:click="showInputs(false, true, false, true, false, true)">
                    <img src="/images/profile/education-icon.svg" alt="">
                    {{ $t('profile.workPlace') }}
                </button>

                <button class="Profile-main__btn" v-on:click="showInputs(false, false, true, true, false, true)">
                    <img src="/images/profile/parent-icon.svg" alt="">
                    {{ $t('profile.profile.files') }}
                </button>
            </div>

            <div class="Profile-main_support white-block">
                <img src="/images/profile-main-support.svg" alt="">
                <div class="Profile-main_support_text">
                    <p class="support_title">{{ $t('profile.profile.help') }}</p>
                    <p class="support_subtitle">
                        {{ $t('profile.profile.help-desc') }}
                    </p>
                </div>
                <div class="write-to-support-button">
                    {{ $t('profile.profile.help-letter') }}
                </div>
            </div>
        </div>
        <div class="Profile-main_right" v-loading="loadingService">
            <div class="photo_recommendations white-block">
                <div class="photo_recommendations_text">
                    <p class="photo_recommendations_title">{{ $t('profile.profile.recommendations-photo') }}</p>
                    <p class="photo_recommendations_content">{{ $t('profile.profile.recommendations-photo-1') }}<br/>{{ $t('profile.profile.recommendations-photo-2') }}<br/>{{ $t('profile.profile.recommendations-photo-3') }}<br/>{{ $t('profile.profile.recommendations-photo-4') }}</p>
                    <p class="photo_recommendations_attention">{{ $t('profile.profile.recommendations-photo-attention') }}</p>
                </div>
            </div>

            <form @submit.prevent="updateProfile">
                <div class="student_info white-block">
                    <ul class="student_info__list">
                        <li class="student_info__item" v-on:click="setActive('main'); showInputs(true, false, false, true)"
                            :class="{ 'active-item': isActive('main') }">
                            {{ $t('profile.profile.main-info') }}
                        </li>
                        <li class="student_info__item" v-on:click="setActive('education'); showInputs(false, true, false, true)"
                            :class="{ 'active-item':isActive('education') }">
                            {{ $t('profile.workPlace') }}
                        </li>
                        <li class="student_info__item" v-on:click="setActive('parent'); showInputs(false, false, true, true)"
                            :class="{ 'active-item': isActive('parent') }">
                            {{ $t('profile.profile.files') }}
                        </li>
                    </ul>
                    <div>
                        <div class="row py-5 mt-3" v-show="visibleMain">
                            <div class="student_info__title-mob">{{ $t('profile.profile.main-info') }}</div>
                            <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                    <label class="form-label">{{ $t('profile.profile.last-name-label') }}</label>
                                    <el-input :placeholder="$t('profile.profile.last-name-placeholder')" v-model="account.last_name"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="form-label">{{ $t('profile.profile.first-name-label') }}</label>
                                    <el-input :placeholder="$t('profile.profile.first-name-placeholder')" v-model="account.first_name"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="form-label">{{ $t('profile.profile.patronymic-label') }}</label>
                                    <el-input :placeholder="$t('profile.profile.patronymic-placeholder')" v-model="account.patronymic"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="form-label">{{ $t('profile.profile.birth-date-label') }}</label>
                                    <el-input
                                        v-model="account.birthdate"
                                        disabled
                                    >
                                    </el-input>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <form-group :label="$t('references.required-field') + ' ' + $t('references.gender')" name="sex"
                                            class="mob-hidden">
                                    <el-select
                                        disabled
                                        :placeholder="$t('references.gender')"
                                        v-model="account.sex">
                                        <el-option
                                            v-for="item in genders"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('references.required-field') + ' ' + $t('profile.profile.email-label')" name="email">
                                    <el-input
                                        id="email"
                                        :placeholder="$t('profile.profile.email-placeholder')"
                                        v-model="account.email"
                                        @change="$v.account.email.$touch()"
                                        clearable>
                                    </el-input>
                                </form-group>
                                <form-group :label="$t('references.required-field') + ' ' + $t('profile.profile.phone-label')"
                                            name="phone">
                                    <el-input
                                        :placeholder="$t('profile.profile.phone-placeholder')"
                                        v-model="phone"
                                        v-mask="'+7 (###) ### ##-##'"
                                        @change="$v.phone.$touch()"
                                        @input="enforcePhoneFormat"
                                        clearable>
                                    </el-input>
                                </form-group>
                                <form-group :label="$t('references.required-field') + ' ' + $t('profile.profile.region-label')"
                                            name="region_id">
                                    <el-select
                                        :placeholder="$t('profile.profile.region-placeholder')"
                                        v-model="account.info.region_id"
                                        :loading="regionsLoading"
                                        @change="getCities(account.info.region_id, true, 'account')"
                                        filterable>
                                        <el-option
                                            v-for="item in regions"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <form-group :label="$t('references.required-field') + ' ' + $t('profile.profile.district-label')"
                                            name="city_id">
                                    <el-select
                                        :placeholder="$t('profile.profile.district-placeholder')"
                                        v-model="account.city_id"
                                        :loading="citiesLoading"
                                        @change="getLocalities(account.city_id, true, 'account')"
                                        filterable>
                                        <el-option
                                            v-for="item in cities"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('references.required-field') + ' ' + $t('profile.profile.city-label')"
                                            name="locality_id"
                                >
                                    <el-select
                                        :placeholder="$t('profile.profile.city-placeholder')"
                                        :loading="localitiesLoading"
                                        autocomplete="nope"
                                        v-model="account.info.locality_id"
                                        filterable>
                                        <el-option
                                            v-for="item in localities"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('references.required-field') + ' ' + $t('profile.instruction-language-label')">
                                    <el-select
                                        v-model="account.info.lang"
                                        :placeholder="$t('profile.instruction-language-placeholder')"
                                        filterable>
                                        <el-option
                                            v-for="item in instructionLangs"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('references.required-field') + ' ' + $t('search-results.lesson-label')">
                                    <el-select
                                        v-model="account.info.subject_id"
                                        :loading="subjectsLoading"
                                        :placeholder="$t('search-results.lesson-label')"
                                        filterable>
                                        <el-option
                                            v-for="item in subjects"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                            </div>
                        </div>
                        <div class="row py-5 mt-3 justify-content-center" v-show="visibleEducation">
                            <div class="student_info__title-mob">{{ $t('profile.workPlace') }}</div>
                            <div class="col-lg-4 col-md-12">
                                <form-group :label="$t('profile.profile.region-label')"
                                            name="region_id">
                                    <el-select
                                        :placeholder="$t('profile.profile.region-placeholder')"
                                        v-model="account.jobs.region_id"
                                        :loading="regionsLoading"
                                        @change="getCities(account.jobs.region_id, true, 'jobs')"
                                        filterable>
                                        <el-option
                                            v-for="item in jobRegions"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('profile.profile.district-label')"
                                            name="city_id">
                                    <el-select
                                        :placeholder="$t('profile.profile.district-placeholder')"
                                        v-model="account.jobs.city_id"
                                        :loading="citiesLoading"
                                        @change="getLocalities(account.jobs.city_id, true, 'jobs')"
                                        filterable>
                                        <el-option
                                            v-for="item in jobCities"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('profile.profile.city-label')"
                                            name="locality_id">
                                    <el-select
                                        :placeholder="$t('profile.profile.city-placeholder')"
                                        :loading="localitiesLoading"
                                        @change="getOrganizations(account.jobs.locality_id, true)"
                                        autocomplete="nope"
                                        v-model="account.jobs.locality_id"
                                        filterable>
                                        <el-option
                                            v-for="item in jobLocalities"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <div class="form-group">
                                    <label class="form-label">{{ $t('profile.profile.organization-name') }}</label>
                                    <el-select
                                        :placeholder="$t('profile.profile.organization-name')"
                                        :loading="organizationsLoading"
                                        autocomplete="nope"
                                        v-model="account.jobs.organization_id"
                                        filterable>
                                        <el-option
                                            v-for="item in organizations"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <form-group :label="$t('profile.profile.position')"
                                            name="position">
                                    <el-input v-model="account.jobs.position" :placeholder="$t('profile.profile.position')">
                                    </el-input>
                                </form-group>
                                <form-group :label="$t('profile.profile.dateStart-dateEnd')"
                                            name="date">
                                    <el-date-picker
                                        :picker-options="pickerOptions"
                                        v-model="account.jobs.date"
                                        type="daterange"
                                        range-separator="-"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        start-placeholder="__.__.__"
                                        end-placeholder="__.__.__">
                                    </el-date-picker>
                                </form-group>
                                <form-group :label="$t('search-results.lesson-label')"
                                            name="subject_id">
                                    <el-select
                                        v-model="account.jobs.subject_id"
                                        :loading="subjectsLoading"
                                        :placeholder="$t('search-results.lesson-label')"
                                        filterable>
                                        <el-option
                                            v-for="item in jobSubjects"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('profile.profile.teaching-experience')"
                                            name="experience">
                                    <el-input-number v-model="account.jobs.experience" controls-position="right" :placeholder="$t('profile.profile.teaching-experience')">
                                    </el-input-number>
                                </form-group>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <form-group :label="$t('profile.profile.category')"
                                            name="category_id">
                                    <el-select
                                        v-model="account.jobs.category_id"
                                        :loading="subjectsLoading"
                                        :placeholder="$t('profile.profile.category')"
                                        filterable>
                                        <el-option
                                            v-for="item in subjects"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('profile.about-your')"
                                            name="about">
                                    <el-input
                                        type="textarea"
                                        v-model="account.jobs.about"
                                        :placeholder="$t('profile.ask-about-your')">
                                    </el-input>
                                </form-group>
                                <!--
                                <div class="form-group">
                                <label class="form-label"></label>
                                <div style="display: flex">
                                    <div class="button-add-work"></div>
                                    <span class="button-add-work-text">{{ $t('profile.profile.add-new-work-place') }}</span>
                                </div>
                                </div>
                                -->
                            </div>
                            <div class="col-sm col-md-12">
                                <form-group :label="$t('profile.workPlace')"
                                            name="about">
                                    <el-input
                                        type="textarea"
                                        v-model="account.jobs.job_description"
                                        :placeholder="$t('profile.profile.write-about-your-work')"
                                    >
                                    </el-input>
                                </form-group>
                            </div>
                        </div>
                        <div class="row py-5 mt-3 justify-content-center" v-show="visibleParent">
                            <div class="student_info__title-mob">{{ $t('profile.profile.files') }}</div>
                            <div class="col-lg-6 col-md-12">
                                <div class="row my-3">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="file-upload-title">{{ $t('profile.certification') }}</div>
                                        <div class="file-upload-text">{{ $t('profile.file-upload') }}</div>
                                        <div class="col-lg-12 col-md-10">
                                            <div class="signup__attach-block">
                                                <el-upload
                                                    class="upload-demo"
                                                    :file-list="idFiles"
                                                    :headers="{Authorization: 'Bearer ' + this.$store.state.auth.token}"
                                                    ref="id_files"
                                                    :action="uploadUrl"
                                                    v-model="account.files.id_files"
                                                    :on-change="onChangeId"
                                                    :on-remove="onRemoveId">
                                                    <div class="signup__image-upload">
                                                        <label slot="trigger" size="small" type="primary" for="file-input-certification" class="signup__attach-btn">
                                                            <img src="/images/attach-icon.svg" width="16" height="16" alt=""/>
                                                            {{ $t('signup.attach-file') }}
                                                        </label>
                                                    </div>
                                                    <div class="signup__attached-file">
                                                        {{ $t('signup.attached-file') }}
                                                    </div>
                                                </el-upload>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row my-3">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="file-upload-title">{{ $t('profile.resume') }}</div>
                                        <div class="file-upload-text">{{ $t('profile.file-upload') }}</div>
                                        <div class="col-lg-12 col-md-10">
                                            <div class="signup__attach-block">
                                                <el-upload
                                                    class="upload-demo"
                                                    :file-list="cvFiles"
                                                    :headers="{Authorization: 'Bearer ' + this.$store.state.auth.token}"
                                                    ref="cv_files"
                                                    v-model="account.files.cv_files"
                                                    :on-change="onChangeCv"
                                                    :on-remove="onRemoveCv"
                                                    :action="uploadUrl">
                                                    <div class="signup__image-upload">
                                                        <label slot="trigger" size="small" type="primary" class="signup__attach-btn">
                                                            <img src="/images/attach-icon.svg" width="16" height="16" alt=""/>
                                                            {{ $t('signup.attach-file') }}
                                                        </label>
                                                    </div>
                                                    <div class="signup__attached-file">
                                                        {{ $t('signup.attached-file') }}
                                                    </div>
                                                </el-upload>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="row my-3">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="file-upload-title">{{ $t('profile.diplomas') }}</div>
                                        <div class="file-upload-text">{{ $t('profile.file-upload') }}</div>
                                        <div class="col-lg-12 col-md-10">
                                            <div class="signup__attach-block">
                                                <el-upload
                                                    class="upload-demo"
                                                    :file-list="diplomaFiles"
                                                    :headers="{Authorization: 'Bearer ' + this.$store.state.auth.token}"
                                                    ref="diploma"
                                                    v-model="account.files.diploma"
                                                    :on-change="onChangeDiploma"
                                                    :on-remove="onRemoveDiploma"
                                                    :action="uploadUrl">
                                                    <div class="signup__image-upload">
                                                        <label slot="trigger" size="small" type="primary" class="signup__attach-btn">
                                                            <img src="/images/attach-icon.svg" width="16" height="16" alt=""/>
                                                            {{ $t('signup.attach-file') }}
                                                        </label>
                                                    </div>
                                                    <div class="signup__attached-file">
                                                        {{ $t('signup.attached-file') }}
                                                    </div>
                                                </el-upload>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--
                                <div class="button-more">
                                    <div class="image-plus"></div>
                                    <span>{{ $t('profile.add-more') }}</span>
                                </div>
                                -->
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="student_info_save btn_lila" v-show="visibleSaveBtn">
                        {{ $t('profile.profile.save-btn') }}
                    </button>
                </div>
            </form>
        </div>
        <div class="Profile-main_support Profile-main_support--mob white-block" v-loading="loadingService">
            <img src="/images/profile-main-support.svg" alt="">
            <div class="Profile-main_support_text">
                <p class="support_title">{{ $t('profile.profile.help') }}</p>
                <p class="support_subtitle">
                    {{ $t('profile.profile.help-desc') }}
                </p>
            </div>
            <div class="write-to-support-button">
                {{ $t('profile.profile.help-letter') }}
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { required, minLength } from "vuelidate/lib/validators";
import VueMask from 'v-mask'
import Datepicker from 'vuejs-datepicker';
import {en, ru, kk} from 'vuejs-datepicker/dist/locale'

export default {
    beforeCreate() {
        Vue.use(VueMask);
    },
    components: {
        Datepicker
    },
    data() {
        return {
            idFiles: [],
            cvFiles: [],
            diplomaFiles: [],
            pickerOptions: {
                firstDayOfWeek: 1
            },
            uploadUrl: window.API_ROOT + '/api/file/upload',
            loadingService: false,
            visibleSaveBtn: true,
            visibleMenuBtns: true,
            visibleBtnBack: false,
            visibleMain: false,
            visibleEducation: false,
            visibleParent: false,
            activeItem: 'main',
            apiRoot: window.API_ROOT,
            en: en,
            ru: ru,
            kk: kk,
            phone: '',
            subjects: [],
            regions: [],
            cities: [],
            localities: [],
            organizations: [],
            jobSubjects: [],
            jobRegions: [],
            jobCities: [],
            jobLocalities: [],
            subjectsLoading: false,
            regionsLoading: false,
            citiesLoading: false,
            localitiesLoading: false,
            organizationsLoading: false,
            loading: false,
            avatar: '',
            avatarImage: '',
            instructionLangs: [
                {
                    id: 'ru',
                    name: 'Русский'
                },
                {
                    id: 'en',
                    name: 'Английский'
                },
                {
                    id: 'kz',
                    name: 'Казахский'
                },
            ],
            account: {
                subject:'',
                first_name: '',
                last_name: '',
                is_resident: '',
                patronymic: '',
                instruction_lang: '',
                email: '',
                phone: '',
                birthdate: '',
                change_of_training_id: '',
                other: '',
                sex: '',
                city_id: '',
                info: {
                    photo: '',
                    locality_id: '',
                    lang: '',
                    subject_id: '',
                    region_id: '',
                },
                jobs: {
                    region_id: '',
                    city_id: '',
                    locality_id: '',
                    organization_id: '',
                    position: '',
                    experience: '',
                    category_id: '',
                    about: '',
                    date: '',
                    from: '',
                    till: '',
                    job_description: ''
                },
                files: {
                    cv_files: [],
                    id_files: [],
                    diploma: []
                }
            },
            genders: [
                {
                    id: 1,
                    name: this.$t('references.gender-male')
                },
                {
                    id: 2,
                    name: this.$t('references.gender-female')
                }
            ],
        }
    },
    validations: {
        account: {
            first_name: { required },
            last_name: { required },
            email: { required },
            phone: { required },
            city_id: { required },
            info: {
                region_id: { required },
                locality_id: { required },
                lang: { required },
                subject_id: { required },
            },
            // jobs: {
            //     region_id: { required },
            //     city_id: { required },
            //     locality_id: { required },
            //     organization_id: { required },
            //     position: { required },
            //     date: { required },
            //     subject_id: { required },
            //     experience: { required },
            //     category_id: { required },
            //     about: { required },
            //     job_description: { required },
            // }
        },
        phone: {
            required: false,
            minLength: minLength(18)
        },
    },
    mounted: function () {
        this.loadingService = true;
        setTimeout(() => {
            this.loadingService = false;
        }, 5000);
        this.getRegions(false, 'account')
        this.getSubjects(false, 'account')
        this.getRegions(false, 'jobs')
        this.getSubjects(false, 'jobs')
        this.$http.get(window.API_ROOT + '/api/teacher/edit')
            .then((res) => {
                // this.subjects=res.body.subjects;
                // this.regions = res.data.regions;
                // this.cities = res.data.districts;
                // this.localities = res.data.localities;
                let user = res.body.data;
                if (user.info) {
                    this.account.info = user.info;
                    this.account.info.region_id = res.body.data.info.region_id;
                    if (this.account.info.region_id != null) {
                        this.getCities(this.account.info.region_id, false, 'account')
                    }
                    this.account.city_id = res.body.data.info.district_id;
                    if (this.account.city_id != null) {
                        this.getLocalities(this.account.city_id, false, 'account')
                    }
                }
                this.avatar = user.avatar;
                this.avatarImage=window.API_ROOT + '/api/user/avatar?file=' + this.avatar
                this.account.email = user.email;
                this.account.birthdate = user.birthdate;
                this.account.first_name = user.first_name;
                this.account.last_name = user.last_name;
                this.account.patronymic = user.patronymic;
                this.account.is_resident = user.is_resident;
                this.account.sex = user.sex;

                if (user.files.cv_files.length) {
                    let files = [];
                    user.files.cv_files.forEach(file => {
                        file.response = file.path;
                        file.name = file.path.substring(6);
                        files.push(file)
                    })
                    this.account.files.cv_files = files
                    this.cvFiles = files
                }
                if (user.files.diploma.length) {
                    let files = [];
                    user.files.diploma.forEach(file => {
                        file.response = file.path;
                        file.name = file.path.substring(6);
                        files.push(file)
                    })
                    this.account.files.diploma = files
                    this.diplomaFiles = files
                }
                if (user.files.id_files.length) {
                    let files = [];
                    user.files.id_files.forEach(file => {
                        file.response = file.path;
                        file.name = file.path.substring(6);
                        files.push(file)
                    })
                    this.account.files.id_files = files
                    this.idFiles = files
                }

                if (user.jobs) {
                    if (user.jobs) {
                        this.account.jobs = user.jobs;
                    }
                    this.account.jobs.region_id = user.jobs.region_id;
                    if (this.account.jobs.region_id != null) {
                        this.getCities(this.account.jobs.region_id, false, 'jobs')
                    }
                    this.account.jobs.city_id = user.jobs.district_id;
                    if (this.account.jobs.city_id != null) {
                        this.getLocalities(this.account.jobs.city_id, false, 'jobs')
                    }
                    this.account.jobs.locality_id = user.jobs.locality_id;
                    if (this.account.jobs.locality_id != null) {
                        this.getOrganizations(this.account.jobs.locality_id, false)
                    }
                }

                this.phone = user.phone;
                this.$v.phone.$touch();

                localStorage.setItem('user', JSON.stringify(res.data.data))
                this.avatar = res.data.link;
                sessionStorage.setItem('avatar-url', res.data.link);

                this.loadingService = false
            })
            .catch((e) => {
                this.loadingService = false
                if (e.status === 401) {
                    this.$router.push('/')
                }
            });
        if ($('body').width() <= 992) {
            this.visibleMain = false;
            this.visibleEducation = false;
            this.visibleParent = false;
            this.visibleSaveBtn = false;
            this.visibleBtnBack = false;
        } else {
            this.visibleMain = true;
            this.visibleEducation = false;
            this.visibleParent = false;
            this.visibleSaveBtn = true;
            this.visibleMenuBtns = true;
            this.visibleBtnBack = false;
        }
    },
    methods: {
        onChangeId(file, fileList) {
            let fl = [];
            fileList.forEach(f => {
                if (f.status === 'success') {
                    f.path = f.response
                }
                fl.push(f)
            })
            this.account.files.id_files = fl
        },
        onRemoveId(file, fileList) {
            this.idFiles = fileList
            this.account.files.id_files = fileList
        },
        onChangeCv(file, fileList) {
            let fl = [];
            fileList.forEach(f => {
                if (f.status === 'success') {
                    f.path = f.response
                }
                fl.push(f)
            })
            this.account.files.cv_files = fl
        },
        onRemoveCv(file, fileList) {
            this.cvFiles = fileList
            this.account.files.cv_files = fileList
        },
        onChangeDiploma(file, fileList) {
            let fl = [];
            fileList.forEach(f => {
                if (f.status === 'success') {
                    console.log(f)
                    f.path = f.response
                }
                fl.push(f)
            })
            this.account.files.diploma = fl
        },
        onRemoveDiploma(file, fileList) {
            this.diplomaFiles = fileList
            this.account.files.diploma = fileList
        },
        showInputs(visibleMain, visibleEducation, visibleParent, visibleSaveBtn, visibleMenuBtns, visibleBtnBack) {
            this.visibleMain = visibleMain;
            this.visibleEducation = visibleEducation;
            this.visibleParent = visibleParent;
            this.visibleSaveBtn = visibleSaveBtn;
            this.visibleMenuBtns = visibleMenuBtns;
            this.visibleBtnBack = visibleBtnBack;
        },
        isActive: function (menuItem) {
            return this.activeItem === menuItem
        },
        setActive: function (menuItem) {
            this.activeItem = menuItem
        },
        handleAvatarSuccess(res, file) {
            this.$router.go()
            this.avatarUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;

            if (!(isJPG || isPNG)) {
                this.$message.error('Avatar picture must be JPG or PNG format!');
            }
            if (!isLt10M) {
                this.$message.error('Avatar picture size can not exceed 10MB!');
            }
            return (isJPG || isPNG) && isLt10M;
        },
        getSubjects(reset = true, tab) {
            this.subjectsLoading = true;
            this.$http.get(window.API_ROOT + '/api/reference/subjects')
                .then((res) => {
                    this.subjectsLoading = false;
                    if (res.body.data) {
                        if (tab === 'account') {
                            this.subjects = res.body.data;
                            if (reset) {
                                this.account.subject = '';
                            }
                        } else if (tab === 'jobs') {
                            this.jobSubjects = res.body.data
                            if (reset) {
                                this.account.jobs.subject = '';
                            }
                        }
                    }
                }).catch(() => {
                this.subjectsLoading = false;
            })
        },
        getRegions(reset = true, tab) {
            this.regionsLoading = true;
            this.$http.get(window.API_ROOT + '/api/reference/regions')
                .then((res) => {
                    this.regionsLoading = false;
                    if (res.body.data) {
                        if (tab === 'account') {
                            this.regions = res.body.data;
                            if (reset) {
                                this.account.info.region_id = '';
                            }
                        } else if (tab === 'jobs') {
                            this.jobRegions = res.body.data;
                            if (reset) {
                                this.account.jobs.region_id = '';
                            }
                        }
                    }
                }).catch(() => {
                this.citiesLoading = false;
            })
        },
        getCities(id, reset = true, tab) {
            this.citiesLoading = true;
            this.$http.get(window.API_ROOT + '/api/reference/districts/' + id)
                .then((res) => {
                    this.citiesLoading = false;
                    if (res.body.data) {
                        if (tab === 'account') {
                            this.cities = res.body.data;
                            if (reset) {
                                this.account.city_id = '';
                            }
                        } else if (tab === 'jobs') {
                            this.jobCities = res.body.data;
                            if (reset) {
                                this.account.jobs.city_id = '';
                            }
                        }
                    }
                }).catch(() => {
                    this.citiesLoading = false;
                })
        },
        getLocalities(id, reset = true, tab) {
            this.localitiesLoading = true;
            this.$http.get(window.API_ROOT + '/api/reference/localities/' + id)
                .then((res) => {
                    this.localitiesLoading = false;
                    if (res.body.data) {
                        if (tab === 'account') {
                            this.localities = res.body.data;
                            if (reset) {
                                this.account.info.locality_id = '';
                            }
                        } else if (tab === 'jobs') {
                            this.jobLocalities = res.body.data;
                            if (reset) {
                                this.account.jobs.locality_id = '';
                            }
                        }
                    }
                }).catch(() => {
                this.localitiesLoading = false;
            })
        },
        getOrganizations(id, reset = true) {
            this.organizationsLoading = true;
            this.$http.get(window.API_ROOT + '/api/reference/schools/' + id)
                .then((res) => {
                    this.organizationsLoading = false;
                    if (res.body.data) {
                        this.organizations = res.body.data;
                        if (reset) {
                            this.account.jobs.organization_id = '';
                        }
                    }
                }).catch(() => {
                this.organizationsLoading = false;
            })
        },
        updateProfile() {
            this.loading = true;
            this.$v.account.$touch();
            if (this.$v.account.$invalid) {
                Vue.toastr({
                    message: 'Ошибка',
                    description: 'Заполните все поля',
                    type: 'error'
                });
                this.loading = false;
            } else {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.loading = false;
                    return
                }
                this.account.jobs.from = this.account.jobs.date[0]
                this.account.jobs.till = this.account.jobs.date[1]
                this.$http.post(window.API_ROOT + '/api/teacher/update', this.account)
                    .then((res) => {
                        this.loading = false;
                        Vue.toastr({
                            message: 'Успешно!',
                            description: res.data.success,
                            type: 'success'
                        });
                    }).catch((e) => {
                    this.loading = false;
                    Vue.toastr({
                        message: 'Ошибка',
                        description: e.body.errors[0],
                        type: 'error'
                    });
                });
            }
        },
        enforcePhoneFormat() {
            this.$v.phone.$touch();
            let x = this.phone
                .replace(/\D/g, "")
                .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

            this.account.phone = x[0];
        },
        submitUpload() {

        }
    }
}
</script>
<style lang="less">
.student_info__title-mob {
    display: none;
}
.Profile-main .Profile-main_support--mob {
    display: none;
}
.el-input-number{
    width: 100%;
}
.el-input-number .el-input__inner {
    text-align: left;
}
.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
    width: 100%;
}
.image-plus{
    background-image: url("/images/button-plus-purple.svg");
    height: 30px;
    width: 30px;
    margin: 5px;
    background-repeat: no-repeat;
}
.student_info__list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.student_info__item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    height: 100%;
    border-bottom: 2px solid #EEEEEE;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #303030;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 4px 16px;
    min-height: 48px;
    cursor: pointer;
}

.student_info__wrapper {
}

.student_info__item.active-item {
    border-bottom: 2px solid #473F95;
    color: #473F95;
}

.button-more{
    display: flex;
    margin-top: 1.5rem;
    margin-left: 13px;
    border: 1px dashed #473F95;
    border-radius: 4px;
    height: 40px;
    width: max-content;
    span{
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        color: #473F95;
        line-height: 40px;
        margin-right: 5px;
    }
}
.file-upload-title{
    margin-left: 13px;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;

    color: #473F95;

}
.file-upload-text{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-left: 13px;
    margin-bottom: 10px;
    color: #A39FCA;
}
.teacher_work_save{
    padding: 0 2rem;
    background-color: white;
    box-shadow: none;
    border-radius: 4px;
    font-family: Inter, Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    border: 2px solid #473F95;
    color: #473F95;
    height: 40px;
}
.button-add-work{
    height: 40px;
    width: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/images/plus_circle.svg");
}
.button-add-work-text{
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(71, 63, 149, 0.5);
}
.Profile-main {
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 50px;
  max-width: 100%;
}
.Profile-main .white-block {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    padding: 26px 17px;
    border-radius: 8px;
}
.Profile-main .Profile-main_left {
    display: flex;
    flex-flow: column;
    width: 27%;
}
.Profile-main .Profile-main_left .Profile-main_photo {
    display: flex;
    flex-flow: column;
    align-items: center;
}
.Profile-main .Profile-main_left .Profile-main_photo .photo_title {
    color: #303030;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.5;
}
.Profile-main .Profile-main_left .Profile-main_photo img {
    border-radius: 100px;
}
.Profile-main .Profile-main_left .Profile-main_photo .avatar_info {
    text-align: center;
    margin-top: 43px;
}
.Profile-main .Profile-main_left .Profile-main_photo .avatar_info p {
    margin: 0
}
.Profile-main .Profile-main_left .Profile-main_photo .avatar_info .name {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
}
.Profile-main .Profile-main_left .Profile-main_support {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: 16px;
}
.Profile-main .Profile-main_left .Profile-main_support .Profile-main_support_text {
    margin: 40px 0 63px;
}
.Profile-main .Profile-main_left .Profile-main_support .Profile-main_support_text .support_title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #303030;
    text-transform: uppercase;
}

.Profile-main .Profile-main_left .Profile-main_support .Profile-main_support_text .support_subtitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #303030;
    font-family: 'Roboto Condensed', sans-serif;
}
.Profile-main .Profile-main_left .Profile-main_support .write-to-support-button {
    background: #473F95;
    border-radius: 5px;
    margin-bottom: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 11px 28.5px;
    cursor: pointer;
}
.Profile-main_right {
    width: 71%;
}
.Profile-main_right .photo_recommendations {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 16px;
}
.Profile-main_right .photo_recommendations .photo_recommendations_title {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #473F95;
}
.Profile-main_right .photo_recommendations .photo_recommendations_content {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #473F95;
}
.Profile-main_right .photo_recommendations .photo_recommendations_attention {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #D23168;
}
.Profile-main_camera {
    background: #473F95;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -6px;
    right: 0;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border: none;
}
.nav-tabs {
    border-bottom: none;
}
.nav-tabs .nav-link {
    height: 100%;
    border-bottom: 2px solid #EEEEEE;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #303030;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 4px 16px;
}
.nav-tabs .nav-link.active {
    border-bottom: 2px solid #473F95;
    color: #473F95;
}
.nav-tabs .nav-link:hover {
    border-bottom: 2px solid #EEEEEE;
    border-color: transparent transparent #473F95;
}
.form-label {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
}
.form-group {
    display: flex;
    flex-direction: column;
}
.student_info_save {
    padding: 14px 36px;
    display: flex;
    margin: 35px auto 0 auto;
}
</style>
<style lang="less" scoped>
.form-group.has-error > .help-block {
    display: none;
}
.signup-form {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    padding: 72px 53px 53px 53px;
    margin-bottom: 32px;
    z-index: 2;
    background-color: #fff;
}
.signup-page-title {
    margin-bottom: 35px;
}
.form-group {
    display: flex;
    flex-direction: column;
}
.el-select {
    margin-bottom: 0;
}
.checkbox-block {
    display: flex;
    flex-direction: row;
}
.form-check-label {
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.form-check-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.signup-accept-block .checkmark {
    position: absolute;
    top: 7px;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid #eee;
    border-radius: 4px;
}
.form-check-label:hover input ~ .checkmark {
    background-color: #eee;
}
.form-check-label input:checked ~ .checkmark {
    background-color: #473F95;
}
.form-check-label input:checked .form-check-label {
    background-color: #473F95;
}
.checkmark:after {
    content: "✔";
    position: absolute;
    display: none;
    left: 3px;
    font-size: 9px;
}
.form-check-label input:checked ~ .checkmark:after {
    display: block;
}
.form-check-label .checkmark:after {
    color: #fff;

}
.el-button.btn-finish.is-disabled {
    background: #CACACA;
    border: 1px solid #CACACA;
    color: #FFFFFF;
}
.btn-finish {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    background: #5E57A2;
    border-radius: 12px;
    padding: 18px 18px;
    color: #FFFFFF;
    margin: 0;
    min-width: 276px;
}
.btn-finish:hover, .btn-finish:focus {
    color: #FFFFFF;
    background: #5E57A2;
}
.signup-requered-top-mob {
    display: none;
}
.signup-accept-block {
    display: flex;
    flex-direction: column;
}
.signup-accept-block--nonresident {
    max-width: 250px;
    margin: 0 auto;
}
.signup-submit-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 34px;
    align-items: center;
}
.gender-mob {
    display: none;
}
/deep/ .signup-form .el-radio {
    display: flex;
    align-items: center;
}
/deep/ .signup-form .el-radio__label {
    color: #2C2C2C;
}
/deep/ .signup-form .el-radio__input.is-checked .el-radio__inner {
    border-color: #5E57A2;
    background: #5E57A2;
}
/deep/ .signup-form .el-radio__inner:hover {
    border-color: #5E57A2;
}
/deep/ .signup-form .el-radio__inner {
    width: 16px;
    height: 16px;
}
/deep/ .signup-form .el-radio__inner::after {
    width: 8px;
    height: 8px;
}
.signup-form  .checkbox-block {
    padding-bottom: 20px;
}
.signup__required-fields {
    color: #D23168;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}
.signup__required-fields--resident {
    padding-top: 42px;
    padding-bottom: 24px;
}
.signup__required-fields--non-resident {
    padding: 38px 0;
}
/deep/ .signup-form .form-group > label {
    color: #2C2C2C;
    font-size: 13px;
    line-height: 16px;
    font-family: 'Inter', sans-serif;
}
/deep/ .signup-date-picker .el-date-table td.current:not(.disabled) span {
    background-color: #5E57A2!important;
}
/deep/ .signup-date-picker .el-date-table td.today span {
    color: #5E57A2;
}
.signup__logo {
    margin-top: -65px;
    margin-bottom: 41px;
    text-align: center;
}
.signup__logo--non {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 48px;
    margin-top: -145px;
    margin-left: -65px;
    max-width: 242px;
    max-height: 219px;
}
.signup-additional-info {
    color: #A9A9A9;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}
.confirm-phone-btn {
    background: #D23168;
    border-radius: 10px;
    border: none;
    letter-spacing: 0.1px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    padding: 12px;
}
.confirm-phone-btn--confirmed {
    background: #CACACA;
    color: #fff;
}
.form-check-label {
    color: #303030;
    font-size: 13px;
    line-height: 15px;
}
.form-check-label-accept {
    color: #D23168;
}
.phone-is-confirmed {
    color: #399300;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
}
.signup__fill-fields {
    color: #E24C4B;
    font-size: 12px;
    line-height: 14px;
    margin: 0 auto 10px auto;
}
.signup-form-bg {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    bottom: 301px;
    max-height: 800px;
}
.btn-close-top {
    border: none;
    background-color: #473F95;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    position: absolute;
    right: -16px;
    top: -16px;
}
.sms-modal-title {
    color: #473F95;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
}
.sms-modal-text {
    color: #303030;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding-bottom: 22px;
    text-align: center;
}
.can-resend-sms {
    color: #D23168;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 38px;
}
.sms-modal-incorrect {
    color: #D23168;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    margin-bottom: 0;
    margin-top: 4px;
}
/deep/ #sms-modal .modal-body {
    background-image: url("/images/vave-bg-modal.svg");
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
}
/deep/ .input.react-code-input-container {
    margin: 0 auto;
    width: 254px!important;
}
/deep/ .react-code-input>input {
    box-shadow: 0 4px 4px rgba(51, 51, 51, 0.04), 0 4px 16px rgba(51, 51, 51, 0.08);
    border-radius: 4px;
    background: #FFFFFF;
    border: none;
    color: #473F95;
    font-size: 40px;
    line-height: 24px;
    margin-right: 26px;
}
/deep/ .react-code-input>input:last-child {
    margin-right: 0;
    border-right: none;
}
/deep/ .react-code-input>input:focus {
    border: 1px solid #473F95!important;
}
.signup__attach-block {
}
.signup__attach-block .upload-demo {
    width: 100%;
}
/deep/ .signup__attach-block .el-upload{
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px dashed #473F95;
    padding: 4px 12px 4px 4px;
    border-radius: 4px;
}
/deep/ .signup__attach-block .el-upload-list{
}
.signup__attach-block .signup__image-upload {
    float: left;
}
.signup__attach-btn {
    border: 1px solid #DDDBDA;
    box-sizing: border-box;
    border-radius: 4px;
    background: #FFFFFF;
    color: #473F95;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-right: 21px;
    padding: 5px 22px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.signup__image-upload input {
    display: none;
}
.signup__attach-btn img {
    margin-right: 8px;
}
.signup__attached-file {
    color: #737373;
    font-size: 13px;
    display: block;
    float: left;
    line-height: 32px;
}
.Profile-main__btns-mob {
    display: none;
}
@media (max-width: 991px) {
    .Profile-main .Profile-main_support--mob {
        display: block;
        margin-top: 36px;
        margin-bottom: 0;
    }
    .student_info__title-mob {
        display: block;
        text-align: center;
        color: #473F95;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        margin: -40px auto 56px auto;
    }
    .signup-form {
        box-shadow: 0 4px 4px rgb(51 51 51 / 4%), 0 4px 16px rgb(51 51 51 / 8%);
        border-radius: 8px;
        padding: 25px 21px;
    }

    .signup-requered-top {
        display: none;
    }

    .signup-requered-top-mob {
        display: block;
    }

    .signup-accept-block {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 16px;
    }

    .signup-requered-fields {
        margin-top: 16px;
    }

    .signup-submit-block {
        justify-content: center;
    }

    .gender-mob {
        display: none;
    }

    .gender-mob .el-radio-group {
        text-align: center;
    }

    .gender-mob .el-radio-button__inner {
        padding: 10px 31px;

    }

    .gender-mob .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background: #18A0FB;
    }

    /* .mob-hidden {
      display: none;
    } */
}
@media (max-width: 991px) {
  .Profile-main {
    flex-direction: column;
    margin-bottom: 10px;
  }

    .Profile-main_right {
        width: 100%;
    }

    .Profile-main_right .photo_recommendations {
        display: none;
    }

    .Profile-main .Profile-main_left {
        width: 100%;
    }

    .Profile-main_right {
        width: 100%;
    }

    .student_info__list {
        display: none;
    }

    .Profile-main__btns-mob {
        display: block;
        margin: 56px 0 36px 0;
    }

    .Profile-main__btn {
        color: #473F95;
        letter-spacing: 0.2px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        padding: 20px;
        background: #EEEAFB;
        border-radius: 15px;
        border: none;
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }

    .Profile-main__btn img {
        margin-right: 34px;
    }

    .student_info.white-block {
        box-shadow: none;
        padding: 0;
    }

    .Profile-main .Profile-main_left .Profile-main_support {
        display: none;
    }

  .Profile-main .Profile-main_support--mob {
    display: block;
    margin-top: 36px;
    margin-bottom: 0;
  }
  .Profile-main_support--mob {
      text-align: center;
  }
  .Profile-main_support--mob .support_title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #303030;
    text-transform: uppercase;
  }
  .Profile-main_support--mob .Profile-main_support_text {
    margin: 40px 0 63px;
}
.Profile-main_support--mob .Profile-main_support_text .support_subtitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #303030;
    font-family: 'Roboto Condensed', sans-serif;
}
.Profile-main_support--mob .write-to-support-button {
    background: #473F95;
    border-radius: 5px;
    margin-bottom: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 11px 28.5px;
    cursor: pointer;
}

    .student_info_save {
        margin-bottom: 41px;
    }

    .student_info__title-mob {
        display: block;
        text-align: center;
        color: #473F95;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        margin: -40px auto 56px auto;
    }

    .Profile-main__back-btn {
        display: flex;
        border: none;
        background: #473F95;
        border-radius: 5px;
        width: 58px;
        height: 55px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
    }

    .Profile-main .Profile-main_left .Profile-main_photo {
        position: relative;
    }
}
@media (max-width: 768px) {
    .btn-block {
        text-align: center;
    }
}

</style>